module.exports={
  "_args": [
    [
      "npm@6.2.0",
      "C:\\Projects\\TBF\\Source"
    ]
  ],
  "_from": "npm@6.2.0",
  "_id": "npm@6.2.0",
  "_inBundle": false,
  "_integrity": "sha512-GnlNsOnxwVJX4WSfyQY0gY3LnUX2cc46XU0eu1g+WSuZgDRUGmw8tuptitJu6byp0RWGT8ZEAKajblwdhQHN8A==",
  "_location": "/npm",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "npm@6.2.0",
    "name": "npm",
    "escapedName": "npm",
    "rawSpec": "6.2.0",
    "saveSpec": null,
    "fetchSpec": "6.2.0"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/npm/-/npm-6.2.0.tgz",
  "_spec": "6.2.0",
  "_where": "C:\\Projects\\TBF\\Source",
  "author": {
    "name": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me"
  },
  "bin": {
    "npm": "./bin/npm-cli.js",
    "npx": "./bin/npx-cli.js"
  },
  "bugs": {
    "url": "https://npm.community/c/bugs"
  },
  "bundleDependencies": [
    "abbrev",
    "ansicolors",
    "ansistyles",
    "aproba",
    "archy",
    "byte-size",
    "cacache",
    "call-limit",
    "bluebird",
    "bin-links",
    "chownr",
    "cmd-shim",
    "columnify",
    "config-chain",
    "debuglog",
    "detect-indent",
    "detect-newline",
    "dezalgo",
    "editor",
    "figgy-pudding",
    "find-npm-prefix",
    "fs-vacuum",
    "fs-write-stream-atomic",
    "gentle-fs",
    "glob",
    "graceful-fs",
    "has-unicode",
    "hosted-git-info",
    "iferr",
    "imurmurhash",
    "inflight",
    "inherits",
    "ini",
    "init-package-json",
    "is-cidr",
    "json-parse-better-errors",
    "JSONStream",
    "lazy-property",
    "libcipm",
    "libnpmhook",
    "libnpx",
    "lockfile",
    "lodash._baseindexof",
    "lodash._baseuniq",
    "lodash._bindcallback",
    "lodash._cacheindexof",
    "lodash._createcache",
    "lodash._getnative",
    "lodash.clonedeep",
    "lodash.restparam",
    "lodash.union",
    "lodash.uniq",
    "lodash.without",
    "lru-cache",
    "meant",
    "mkdirp",
    "mississippi",
    "move-concurrently",
    "nopt",
    "normalize-package-data",
    "npm-audit-report",
    "npm-cache-filename",
    "npm-lifecycle",
    "npm-install-checks",
    "npm-package-arg",
    "npm-packlist",
    "npm-pick-manifest",
    "npm-profile",
    "npm-registry-client",
    "npm-registry-fetch",
    "npm-user-validate",
    "npmlog",
    "once",
    "opener",
    "osenv",
    "pacote",
    "path-is-inside",
    "promise-inflight",
    "query-string",
    "qrcode-terminal",
    "qw",
    "read",
    "read-cmd-shim",
    "read-installed",
    "read-package-json",
    "read-package-tree",
    "readable-stream",
    "readdir-scoped-modules",
    "request",
    "retry",
    "rimraf",
    "semver",
    "sha",
    "slide",
    "sorted-object",
    "sorted-union-stream",
    "ssri",
    "tar",
    "text-table",
    "uid-number",
    "umask",
    "unique-filename",
    "unpipe",
    "update-notifier",
    "uuid",
    "validate-npm-package-license",
    "validate-npm-package-name",
    "which",
    "wrappy",
    "write-file-atomic",
    "safe-buffer",
    "worker-farm",
    "tiny-relative-date",
    "cli-columns",
    "cli-table3",
    "node-gyp",
    "lock-verify"
  ],
  "config": {
    "publishtest": false
  },
  "contributors": [
    {
      "name": "Isaac Z. Schlueter",
      "email": "i@izs.me"
    },
    {
      "name": "Steve Steiner",
      "email": "ssteinerX@gmail.com"
    },
    {
      "name": "Mikeal Rogers",
      "email": "mikeal.rogers@gmail.com"
    },
    {
      "name": "Aaron Blohowiak",
      "email": "aaron.blohowiak@gmail.com"
    },
    {
      "name": "Martyn Smith",
      "email": "martyn@dollyfish.net.nz"
    },
    {
      "name": "Charlie Robbins",
      "email": "charlie.robbins@gmail.com"
    },
    {
      "name": "Francisco Treacy",
      "email": "francisco.treacy@gmail.com"
    },
    {
      "name": "Cliffano Subagio",
      "email": "cliffano@gmail.com"
    },
    {
      "name": "Christian Eager",
      "email": "christian.eager@nokia.com"
    },
    {
      "name": "Dav Glass",
      "email": "davglass@gmail.com"
    },
    {
      "name": "Alex K. Wolfe",
      "email": "alexkwolfe@gmail.com"
    },
    {
      "name": "James Sanders",
      "email": "jimmyjazz14@gmail.com"
    },
    {
      "name": "Reid Burke",
      "email": "me@reidburke.com"
    },
    {
      "name": "Arlo Breault",
      "email": "arlolra@gmail.com"
    },
    {
      "name": "Timo Derstappen",
      "email": "teemow@gmail.com"
    },
    {
      "name": "Bart Teeuwisse",
      "email": "bart.teeuwisse@thecodemill.biz"
    },
    {
      "name": "Ben Noordhuis",
      "email": "info@bnoordhuis.nl"
    },
    {
      "name": "Tor Valamo",
      "email": "tor.valamo@gmail.com"
    },
    {
      "name": "Whyme.Lyu",
      "email": "5longluna@gmail.com"
    },
    {
      "name": "Olivier Melcher",
      "email": "olivier.melcher@gmail.com"
    },
    {
      "name": "Tomaž Muraus",
      "email": "kami@k5-storitve.net"
    },
    {
      "name": "Evan Meagher",
      "email": "evan.meagher@gmail.com"
    },
    {
      "name": "Orlando Vazquez",
      "email": "ovazquez@gmail.com"
    },
    {
      "name": "Kai Chen",
      "email": "kaichenxyz@gmail.com"
    },
    {
      "name": "George Miroshnykov",
      "email": "gmiroshnykov@lohika.com"
    },
    {
      "name": "Geoff Flarity",
      "email": "geoff.flarity@gmail.com"
    },
    {
      "name": "Max Goodman",
      "email": "c@chromakode.com"
    },
    {
      "name": "Pete Kruckenberg",
      "email": "pete@kruckenberg.com"
    },
    {
      "name": "Laurie Harper",
      "email": "laurie@holoweb.net"
    },
    {
      "name": "Chris Wong",
      "email": "chris@chriswongstudio.com"
    },
    {
      "name": "Scott Bronson",
      "email": "brons_github@rinspin.com"
    },
    {
      "name": "Federico Romero",
      "email": "federomero@gmail.com"
    },
    {
      "name": "Visnu Pitiyanuvath",
      "email": "visnupx@gmail.com"
    },
    {
      "name": "Irakli Gozalishvili",
      "email": "rfobic@gmail.com"
    },
    {
      "name": "Mark Cahill",
      "email": "mark@tiemonster.info"
    },
    {
      "name": "Tony",
      "email": "zearin@gonk.net"
    },
    {
      "name": "Iain Sproat",
      "email": "iainsproat@gmail.com"
    },
    {
      "name": "Trent Mick",
      "email": "trentm@gmail.com"
    },
    {
      "name": "Felix Geisendörfer",
      "email": "felix@debuggable.com"
    },
    {
      "name": "Jameson Little",
      "email": "t.jameson.little@gmail.com"
    },
    {
      "name": "Conny Brunnkvist",
      "email": "conny@fuchsia.se"
    },
    {
      "name": "Will Elwood",
      "email": "w.elwood08@gmail.com"
    },
    {
      "name": "Dean Landolt",
      "email": "dean@deanlandolt.com"
    },
    {
      "name": "Oleg Efimov",
      "email": "efimovov@gmail.com"
    },
    {
      "name": "Martin Cooper",
      "email": "mfncooper@gmail.com"
    },
    {
      "name": "Jann Horn",
      "email": "jannhorn@googlemail.com"
    },
    {
      "name": "Andrew Bradley",
      "email": "cspotcode@gmail.com"
    },
    {
      "name": "Maciej Małecki",
      "email": "me@mmalecki.com"
    },
    {
      "name": "Stephen Sugden",
      "email": "glurgle@gmail.com"
    },
    {
      "name": "Michael Budde",
      "email": "mbudde@gmail.com"
    },
    {
      "name": "Jason Smith",
      "email": "jhs@iriscouch.com"
    },
    {
      "name": "Gautham Pai",
      "email": "buzypi@gmail.com"
    },
    {
      "name": "David Trejo",
      "email": "david.daniel.trejo@gmail.com"
    },
    {
      "name": "Paul Vorbach",
      "email": "paul@vorb.de"
    },
    {
      "name": "George Ornbo",
      "email": "george@shapeshed.com"
    },
    {
      "name": "Tim Oxley",
      "email": "secoif@gmail.com"
    },
    {
      "name": "Tyler Green",
      "email": "tyler.green2@gmail.com"
    },
    {
      "name": "Dave Pacheco",
      "email": "dap@joyent.com"
    },
    {
      "name": "Danila Gerasimov",
      "email": "danila.gerasimov@gmail.com"
    },
    {
      "name": "Rod Vagg",
      "email": "rod@vagg.org"
    },
    {
      "name": "Christian Howe",
      "email": "coderarity@gmail.com"
    },
    {
      "name": "Andrew Lunny",
      "email": "alunny@gmail.com"
    },
    {
      "name": "Henrik Hodne",
      "email": "dvyjones@binaryhex.com"
    },
    {
      "name": "Adam Blackburn",
      "email": "regality@gmail.com"
    },
    {
      "name": "Kris Windham",
      "email": "kriswindham@gmail.com"
    },
    {
      "name": "Jens Grunert",
      "email": "jens.grunert@gmail.com"
    },
    {
      "name": "Joost-Wim Boekesteijn",
      "email": "joost-wim@boekesteijn.nl"
    },
    {
      "name": "Dalmais Maxence",
      "email": "root@ip-10-195-202-5.ec2.internal"
    },
    {
      "name": "Marcus Ekwall",
      "email": "marcus.ekwall@gmail.com"
    },
    {
      "name": "Aaron Stacy",
      "email": "aaron.r.stacy@gmail.com"
    },
    {
      "name": "Phillip Howell",
      "email": "phowell@cothm.org"
    },
    {
      "name": "Domenic Denicola",
      "email": "domenic@domenicdenicola.com"
    },
    {
      "name": "James Halliday",
      "email": "mail@substack.net"
    },
    {
      "name": "Jeremy Cantrell",
      "email": "jmcantrell@gmail.com"
    },
    {
      "name": "Ribettes",
      "email": "patlogan29@gmail.com"
    },
    {
      "name": "Don Park",
      "email": "donpark@docuverse.com"
    },
    {
      "name": "Einar Otto Stangvik",
      "email": "einaros@gmail.com"
    },
    {
      "name": "Kei Son",
      "email": "heyacct@gmail.com"
    },
    {
      "name": "Nicolas Morel",
      "email": "marsup@gmail.com"
    },
    {
      "name": "Mark Dube",
      "email": "markisdee@gmail.com"
    },
    {
      "name": "Nathan Rajlich",
      "email": "nathan@tootallnate.net"
    },
    {
      "name": "Maxim Bogushevich",
      "email": "boga1@mail.ru"
    },
    {
      "name": "Meaglin",
      "email": "Meaglin.wasabi@gmail.com"
    },
    {
      "name": "Ben Evans",
      "email": "ben@bensbit.co.uk"
    },
    {
      "name": "Nathan Zadoks",
      "email": "nathan@nathan7.eu"
    },
    {
      "name": "Brian White",
      "email": "mscdex@mscdex.net"
    },
    {
      "name": "Jed Schmidt",
      "email": "tr@nslator.jp"
    },
    {
      "name": "Ian Livingstone",
      "email": "ianl@cs.dal.ca"
    },
    {
      "name": "Patrick Pfeiffer",
      "email": "patrick@buzzle.at"
    },
    {
      "name": "Paul Miller",
      "email": "paul@paulmillr.com"
    },
    {
      "name": "Ryan Emery",
      "email": "seebees@gmail.com"
    },
    {
      "name": "Carl Lange",
      "email": "carl@flax.ie"
    },
    {
      "name": "Jan Lehnardt",
      "email": "jan@apache.org"
    },
    {
      "name": "Stuart P. Bentley",
      "email": "stuart@testtrack4.com"
    },
    {
      "name": "Johan Sköld",
      "email": "johan@skold.cc"
    },
    {
      "name": "Stuart Knightley",
      "email": "stuart@stuartk.com"
    },
    {
      "name": "Niggler",
      "email": "nirk.niggler@gmail.com"
    },
    {
      "name": "Paolo Fragomeni",
      "email": "paolo@async.ly"
    },
    {
      "name": "Jaakko Manninen",
      "email": "jaakko@rocketpack.fi"
    },
    {
      "name": "Luke Arduini",
      "email": "luke.arduini@gmail.com"
    },
    {
      "name": "Larz Conwell",
      "email": "larz@larz-laptop.(none)",
      "url": "none"
    },
    {
      "name": "Marcel Klehr",
      "email": "mklehr@gmx.net"
    },
    {
      "name": "Robert Kowalski",
      "email": "rok@kowalski.gd"
    },
    {
      "name": "Forbes Lindesay",
      "email": "forbes@lindesay.co.uk"
    },
    {
      "name": "Vaz Allen",
      "email": "vaz@tryptid.com"
    },
    {
      "name": "Jake Verbaten",
      "email": "raynos2@gmail.com"
    },
    {
      "name": "Schabse Laks",
      "email": "Dev@SLaks.net"
    },
    {
      "name": "Florian Margaine",
      "email": "florian@margaine.com"
    },
    {
      "name": "Johan Nordberg",
      "email": "its@johan-nordberg.com"
    },
    {
      "name": "Ian Babrou",
      "email": "ibobrik@gmail.com"
    },
    {
      "name": "Di Wu",
      "email": "dwu@palantir.com"
    },
    {
      "name": "Mathias Bynens",
      "email": "mathias@qiwi.be"
    },
    {
      "name": "Matt McClure",
      "email": "matt.mcclure@mapmyfitness.com"
    },
    {
      "name": "Matt Lunn",
      "email": "matt@mattlunn.me.uk"
    },
    {
      "name": "Alexey Kreschuk",
      "email": "akrsch@gmail.com"
    },
    {
      "name": "elisee",
      "email": "elisee@sparklin.org"
    },
    {
      "name": "Robert Gieseke",
      "email": "robert.gieseke@gmail.com"
    },
    {
      "name": "François Frisch",
      "email": "francoisfrisch@gmail.com"
    },
    {
      "name": "Trevor Burnham",
      "email": "tburnham@hubspot.com"
    },
    {
      "name": "Alan Shaw",
      "email": "alan@freestyle-developments.co.uk"
    },
    {
      "name": "TJ Holowaychuk",
      "email": "tj@vision-media.ca"
    },
    {
      "name": "Nicholas Kinsey",
      "email": "pyro@feisty.io"
    },
    {
      "name": "Paulo Cesar",
      "email": "pauloc062@gmail.com"
    },
    {
      "name": "Elan Shanker",
      "email": "elan.shanker@gmail.com"
    },
    {
      "name": "Jon Spencer",
      "email": "jon@jonspencer.ca"
    },
    {
      "name": "Jason Diamond",
      "email": "jason@diamond.name"
    },
    {
      "name": "Maximilian Antoni",
      "email": "mail@maxantoni.de"
    },
    {
      "name": "Thom Blake",
      "email": "tblake@brightroll.com"
    },
    {
      "name": "Jess Martin",
      "email": "jessmartin@gmail.com"
    },
    {
      "name": "Spain Train",
      "email": "michael.spainhower@opower.com"
    },
    {
      "name": "Alex Rodionov",
      "email": "p0deje@gmail.com"
    },
    {
      "name": "Matt Colyer",
      "email": "matt@colyer.name"
    },
    {
      "name": "Evan You",
      "email": "yyx990803@gmail.com"
    },
    {
      "name": "bitspill",
      "email": "bitspill+github@bitspill.net"
    },
    {
      "name": "Gabriel Falkenberg",
      "email": "gabriel.falkenberg@gmail.com"
    },
    {
      "name": "Alexej Yaroshevich",
      "email": "alex@qfox.ru"
    },
    {
      "name": "Quim Calpe",
      "email": "quim@kalpe.com"
    },
    {
      "name": "Steve Mason",
      "email": "stevem@brandwatch.com"
    },
    {
      "name": "Wil Moore III",
      "email": "wil.moore@wilmoore.com"
    },
    {
      "name": "Sergey Belov",
      "email": "peimei@ya.ru"
    },
    {
      "name": "Tom Huang",
      "email": "hzlhu.dargon@gmail.com"
    },
    {
      "name": "CamilleM",
      "email": "camille.moulin@alterway.fr"
    },
    {
      "name": "Sébastien Santoro",
      "email": "dereckson@espace-win.org"
    },
    {
      "name": "Evan Lucas",
      "email": "evan@btc.com"
    },
    {
      "name": "Quinn Slack",
      "email": "qslack@qslack.com"
    },
    {
      "name": "Alex Kocharin",
      "email": "alex@kocharin.ru"
    },
    {
      "name": "Daniel Santiago",
      "email": "daniel.santiago@highlevelwebs.com"
    },
    {
      "name": "Denis Gladkikh",
      "email": "outcoldman@gmail.com"
    },
    {
      "name": "Andrew Horton",
      "email": "andrew.j.horton@gmail.com"
    },
    {
      "name": "Zeke Sikelianos",
      "email": "zeke@sikelianos.com"
    },
    {
      "name": "Dylan Greene",
      "email": "dylang@gmail.com"
    },
    {
      "name": "Franck Cuny",
      "email": "franck.cuny@gmail.com"
    },
    {
      "name": "Yeonghoon Park",
      "email": "sola92@gmail.com"
    },
    {
      "name": "Rafael de Oleza",
      "email": "rafa@spotify.com"
    },
    {
      "name": "Mikola Lysenko",
      "email": "mikolalysenko@gmail.com"
    },
    {
      "name": "Yazhong Liu",
      "email": "yorkiefixer@gmail.com"
    },
    {
      "name": "Neil Gentleman",
      "email": "ngentleman@gmail.com"
    },
    {
      "name": "Kris Kowal",
      "email": "kris.kowal@cixar.com"
    },
    {
      "name": "Alex Gorbatchev",
      "email": "alex.gorbatchev@gmail.com"
    },
    {
      "name": "Shawn Wildermuth",
      "email": "shawn@wildermuth.com"
    },
    {
      "name": "Wesley de Souza",
      "email": "wesleywex@gmail.com"
    },
    {
      "name": "yoyoyogi",
      "email": "yogesh.k@gmail.com"
    },
    {
      "name": "J. Tangelder",
      "email": "j.tangelder@gmail.com"
    },
    {
      "name": "Jean Lauliac",
      "email": "jean@lauliac.com"
    },
    {
      "name": "Andrey Kislyuk",
      "email": "kislyuk@gmail.com"
    },
    {
      "name": "Thorsten Lorenz",
      "email": "thlorenz@gmx.de"
    },
    {
      "name": "Julian Gruber",
      "email": "julian@juliangruber.com"
    },
    {
      "name": "Benjamin Coe",
      "email": "bencoe@gmail.com"
    },
    {
      "name": "Alex Ford",
      "email": "Alex.Ford@CodeTunnel.com"
    },
    {
      "name": "Matt Hickford",
      "email": "matt.hickford@gmail.com"
    },
    {
      "name": "Sean McGivern",
      "email": "sean.mcgivern@rightscale.com"
    },
    {
      "name": "C J Silverio",
      "email": "ceejceej@gmail.com"
    },
    {
      "name": "Robin Tweedie",
      "email": "robin@songkick.com"
    },
    {
      "name": "Miroslav Bajtoš",
      "email": "miroslav@strongloop.com"
    },
    {
      "name": "David Glasser",
      "email": "glasser@davidglasser.net"
    },
    {
      "name": "Gianluca Casati",
      "email": "casati_gianluca@yahoo.it"
    },
    {
      "name": "Forrest L Norvell",
      "email": "ogd@aoaioxxysz.net"
    },
    {
      "name": "Karsten Tinnefeld",
      "email": "k.tinnefeld@googlemail.com"
    },
    {
      "name": "Bryan Burgers",
      "email": "bryan@burgers.io"
    },
    {
      "name": "David Beitey",
      "email": "david@davidjb.com"
    },
    {
      "name": "Evan You",
      "email": "yyou@google.com"
    },
    {
      "name": "Zach Pomerantz",
      "email": "zmp@umich.edu"
    },
    {
      "name": "Chris Williams",
      "email": "cwilliams88@gmail.com"
    },
    {
      "name": "sudodoki",
      "email": "smd.deluzion@gmail.com"
    },
    {
      "name": "Mick Thompson",
      "email": "dthompson@gmail.com"
    },
    {
      "name": "Felix Rabe",
      "email": "felix@rabe.io"
    },
    {
      "name": "Michael Hayes",
      "email": "michael@hayes.io"
    },
    {
      "name": "Chris Dickinson",
      "email": "christopher.s.dickinson@gmail.com"
    },
    {
      "name": "Bradley Meck",
      "email": "bradley.meck@gmail.com"
    },
    {
      "name": "GeJ",
      "email": "geraud@gcu.info"
    },
    {
      "name": "Andrew Terris",
      "email": "atterris@gmail.com"
    },
    {
      "name": "Michael Nisi",
      "email": "michael.nisi@gmail.com"
    },
    {
      "name": "fengmk2",
      "email": "fengmk2@gmail.com"
    },
    {
      "name": "Adam Meadows",
      "email": "adam.meadows@gmail.com"
    },
    {
      "name": "Chulki Lee",
      "email": "chulki.lee@gmail.com"
    },
    {
      "name": "不四",
      "email": "busi.hyy@taobao.com"
    },
    {
      "name": "dead_horse",
      "email": "dead_horse@qq.com"
    },
    {
      "name": "Kenan Yildirim",
      "email": "kenan@kenany.me"
    },
    {
      "name": "Laurie Voss",
      "email": "git@seldo.com"
    },
    {
      "name": "Rebecca Turner",
      "email": "me@re-becca.org"
    },
    {
      "name": "Hunter Loftis",
      "email": "hunter@hunterloftis.com"
    },
    {
      "name": "Peter Richardson",
      "email": "github@zoomy.net"
    },
    {
      "name": "Jussi Kalliokoski",
      "email": "jussi.kalliokoski@gmail.com"
    },
    {
      "name": "Filip Weiss",
      "email": "me@fiws.net"
    },
    {
      "name": "Timo Weiß",
      "email": "timoweiss@Timo-MBP.local"
    },
    {
      "name": "Christopher Hiller",
      "email": "chiller@badwing.com"
    },
    {
      "name": "Jérémy Lal",
      "email": "kapouer@melix.org"
    },
    {
      "name": "Anders Janmyr",
      "email": "anders@janmyr.com"
    },
    {
      "name": "Chris Meyers",
      "email": "chris.meyers.fsu@gmail.com"
    },
    {
      "name": "Ludwig Magnusson",
      "email": "ludwig@mediatool.com"
    },
    {
      "name": "Wout Mertens",
      "email": "Wout.Mertens@gmail.com"
    },
    {
      "name": "Nick Santos",
      "email": "nick@medium.com"
    },
    {
      "name": "Terin Stock",
      "email": "terinjokes@gmail.com"
    },
    {
      "name": "Faiq Raza",
      "email": "faiqrazarizvi@gmail.com"
    },
    {
      "name": "Thomas Torp",
      "email": "thomas@erupt.no"
    },
    {
      "name": "Sam Mikes",
      "email": "smikes@cubane.com"
    },
    {
      "name": "Mat Tyndall",
      "email": "mat.tyndall@gmail.com"
    },
    {
      "name": "Tauren Mills",
      "email": "tauren@sportzing.com"
    },
    {
      "name": "Ron Martinez",
      "email": "ramartin.net@gmail.com"
    },
    {
      "name": "Kazuhito Hokamura",
      "email": "k.hokamura@gmail.com"
    },
    {
      "name": "Tristan Davies",
      "email": "github@tristan.io"
    },
    {
      "name": "David Volm",
      "email": "david@volminator.com"
    },
    {
      "name": "Lin Clark",
      "email": "lin.w.clark@gmail.com"
    },
    {
      "name": "Ben Page",
      "email": "bpage@dewalch.com"
    },
    {
      "name": "Jeff Jo",
      "email": "jeffjo@squareup.com"
    },
    {
      "name": "martinvd",
      "email": "martinvdpub@gmail.com"
    },
    {
      "name": "Mark J. Titorenko",
      "email": "nospam-github.com@titorenko.net"
    },
    {
      "name": "Oddur Sigurdsson",
      "email": "oddurs@gmail.com"
    },
    {
      "name": "Eric Mill",
      "email": "eric@konklone.com"
    },
    {
      "name": "Gabriel Barros",
      "email": "descartavel1@gmail.com"
    },
    {
      "name": "KevinSheedy",
      "email": "kevinsheedy@gmail.com"
    },
    {
      "name": "Aleksey Smolenchuk",
      "email": "aleksey@uber.com"
    },
    {
      "name": "Ed Morley",
      "email": "emorley@mozilla.com"
    },
    {
      "name": "Blaine Bublitz",
      "email": "blaine@iceddev.com"
    },
    {
      "name": "Andrey Fedorov",
      "email": "anfedorov@gmail.com"
    },
    {
      "name": "Daijiro Wachi",
      "email": "daijiro.wachi@gmail.com"
    },
    {
      "name": "Luc Thevenard",
      "email": "lucthevenard@gmail.com"
    },
    {
      "name": "Aria Stewart",
      "email": "aredridel@nbtsc.org"
    },
    {
      "name": "Charlie Rudolph",
      "email": "charles.w.rudolph@gmail.com"
    },
    {
      "name": "Vladimir Rutsky",
      "email": "rutsky@users.noreply.github.com"
    },
    {
      "name": "Isaac Murchie",
      "email": "isaac@saucelabs.com"
    },
    {
      "name": "Marcin Wosinek",
      "email": "marcin.wosinek@gmail.com"
    },
    {
      "name": "David Marr",
      "email": "davemarr@gmail.com"
    },
    {
      "name": "Bryan English",
      "email": "bryan@bryanenglish.com"
    },
    {
      "name": "Anthony Zotti",
      "email": "amZotti@users.noreply.github.com"
    },
    {
      "name": "Karl Horky",
      "email": "karl.horky@gmail.com"
    },
    {
      "name": "Jordan Harband",
      "email": "ljharb@gmail.com"
    },
    {
      "name": "Guðlaugur Stefán Egilsson",
      "email": "gulli@kolibri.is"
    },
    {
      "name": "Helge Skogly Holm",
      "email": "helge.holm@gmail.com"
    },
    {
      "name": "Peter A. Shevtsov",
      "email": "petr.shevtsov@gmail.com"
    },
    {
      "name": "Alain Kalker",
      "email": "a.c.kalker@gmail.com"
    },
    {
      "name": "Bryant Williams",
      "email": "b.n.williams@gmail.com"
    },
    {
      "name": "Jonas Weber",
      "email": "github@jonasw.de"
    },
    {
      "name": "Tim Whidden",
      "email": "twhid@twhid.com"
    },
    {
      "name": "Andreas",
      "email": "functino@users.noreply.github.com"
    },
    {
      "name": "Karolis Narkevicius",
      "email": "karolis.n@gmail.com"
    },
    {
      "name": "Adrian Lynch",
      "email": "adi_ady_ade@hotmail.com"
    },
    {
      "name": "Richard Littauer",
      "email": "richard.littauer@gmail.com"
    },
    {
      "name": "Oli Evans",
      "email": "oli@zilla.org.uk"
    },
    {
      "name": "Matt Brennan",
      "email": "mattyb1000@gmail.com"
    },
    {
      "name": "Jeff Barczewski",
      "email": "jeff.barczewski@gmail.com"
    },
    {
      "name": "Danny Fritz",
      "email": "dannyfritz@gmail.com"
    },
    {
      "name": "Takaya Kobayashi",
      "email": "jigsaw@live.jp"
    },
    {
      "name": "Ra'Shaun Stovall",
      "email": "rashaunstovall@gmail.com"
    },
    {
      "name": "Julien Meddah",
      "email": "julien.meddah@deveryware.com"
    },
    {
      "name": "Michiel Sikma",
      "email": "michiel@wedemandhtml.com"
    },
    {
      "name": "Jakob Krigovsky",
      "email": "jakob.krigovsky@gmail.com"
    },
    {
      "name": "Charmander",
      "email": "~@charmander.me"
    },
    {
      "name": "Erik Wienhold",
      "email": "git@ewie.name"
    },
    {
      "name": "James Butler",
      "email": "james.butler@sandfox.co.uk"
    },
    {
      "name": "Kevin Kragenbrink",
      "email": "kevin@gaikai.com"
    },
    {
      "name": "Arnaud Rinquin",
      "email": "rinquin.arnaud@gmail.com"
    },
    {
      "name": "Mike MacCana",
      "email": "mike.maccana@gmail.com"
    },
    {
      "name": "Antti Mattila",
      "email": "anttti@fastmail.fm"
    },
    {
      "name": "laiso",
      "email": "laiso@lai.so"
    },
    {
      "name": "Matt Zorn",
      "email": "zornme@gmail.com"
    },
    {
      "name": "Kyle Mitchell",
      "email": "kyle@kemitchell.com"
    },
    {
      "name": "Jeremiah Senkpiel",
      "email": "fishrock123@rocketmail.com"
    },
    {
      "name": "Michael Klein",
      "email": "mischkl@users.noreply.github.com"
    },
    {
      "name": "Simen Bekkhus",
      "email": "sbekkhus91@gmail.com"
    },
    {
      "name": "Victor",
      "email": "victor.shih@gmail.com"
    },
    {
      "name": "thefourtheye",
      "email": "thechargingvolcano@gmail.com"
    },
    {
      "name": "Clay Carpenter",
      "email": "claycarpenter@gmail.com"
    },
    {
      "name": "bangbang93",
      "email": "bangbang93@163.com"
    },
    {
      "name": "Nick Malaguti",
      "email": "nmalaguti@palantir.com"
    },
    {
      "name": "Cedric Nelson",
      "email": "cedric.nelson@gmail.com"
    },
    {
      "name": "Kat Marchán",
      "email": "kzm@zkat.tech"
    },
    {
      "name": "Andrew",
      "email": "talktome@aboutandrew.co.uk"
    },
    {
      "name": "Eduardo Pinho",
      "email": "enet4mikeenet@gmail.com"
    },
    {
      "name": "Rachel Hutchison",
      "email": "rhutchix@intel.com"
    },
    {
      "name": "Ryan Temple",
      "email": "ryantemple145@gmail.com"
    },
    {
      "name": "Eugene Sharygin",
      "email": "eush77@gmail.com"
    },
    {
      "name": "James Talmage",
      "email": "james@talmage.io"
    },
    {
      "name": "jane arc",
      "email": "jane@uber.com"
    },
    {
      "name": "Joseph Dykstra",
      "email": "josephdykstra@gmail.com"
    },
    {
      "name": "Andrew Crites",
      "email": "ajcrites@gmail.com"
    },
    {
      "name": "Joshua Egan",
      "email": "josh-egan@users.noreply.github.com"
    },
    {
      "name": "Carlos Alberto",
      "email": "euprogramador@gmail.com"
    },
    {
      "name": "Thomas Cort",
      "email": "thomasc@ssimicro.com"
    },
    {
      "name": "Thaddee Tyl",
      "email": "thaddee.tyl@gmail.com"
    },
    {
      "name": "Steve Klabnik",
      "email": "steve@steveklabnik.com"
    },
    {
      "name": "Andrew Murray",
      "email": "radarhere@gmail.com"
    },
    {
      "name": "Stephan Bönnemann",
      "email": "stephan@excellenteasy.com"
    },
    {
      "name": "Kyle M. Tarplee",
      "email": "kyle.tarplee@numerica.us"
    },
    {
      "name": "Derek Peterson",
      "email": "derekpetey@gmail.com"
    },
    {
      "name": "Greg Whiteley",
      "email": "greg.whiteley@atomos.com"
    },
    {
      "name": "murgatroid99",
      "email": "mlumish@google.com"
    },
    {
      "name": "Marcin Cieslak",
      "email": "saper@saper.info"
    },
    {
      "name": "João Reis",
      "email": "reis@janeasystems.com"
    },
    {
      "name": "Matthew Hasbach",
      "email": "hasbach.git@gmail.com"
    },
    {
      "name": "Jon Hall",
      "email": "jon_hall@outlook.com"
    },
    {
      "name": "Anna Henningsen",
      "email": "sqrt@entless.org"
    },
    {
      "name": "James Treworgy",
      "email": "jamietre@gmail.com"
    },
    {
      "name": "James Hartig",
      "email": "james@levenlabs.com"
    },
    {
      "name": "Stephanie Snopek",
      "email": "stephaniesnopek@gmail.com"
    },
    {
      "name": "Kent C. Dodds",
      "email": "kent@doddsfamily.us"
    },
    {
      "name": "Aaron Krause",
      "email": "aaronjkrause@gmail.com"
    },
    {
      "name": "Daniel K O'Leary",
      "email": "daniel@dko.io"
    },
    {
      "name": "fscherwi",
      "email": "fscherwi@users.noreply.github.com"
    },
    {
      "name": "Thomas Reggi",
      "email": "thomas@reggi.com"
    },
    {
      "name": "Thomas Michael McTiernan",
      "email": "thomasmctiernan@gmail.com"
    },
    {
      "name": "Jason Kurian",
      "email": "JaKXz@users.noreply.github.com"
    },
    {
      "name": "Sebastiaan Deckers",
      "email": "seb@ninja.sg"
    },
    {
      "name": "lady3bean",
      "email": "lady3bean@users.noreply.github.com"
    },
    {
      "name": "Tomi Carr",
      "email": "TaMe3971@users.noreply.github.com"
    },
    {
      "name": "Juan Caicedo",
      "email": "retiredcanadianpoet@gmail.com"
    },
    {
      "name": "Ashley Williams",
      "email": "ashley@npmjs.com"
    },
    {
      "name": "Andrew Marcinkevičius",
      "email": "andrew.web@ifdattic.com"
    },
    {
      "name": "Jorrit Schippers",
      "email": "jorrit@ncode.nl"
    },
    {
      "name": "Alex Lukin",
      "email": "alex.lukin@softgrad.com"
    },
    {
      "name": "Aria Stewart",
      "email": "aredridel@dinhe.net"
    },
    {
      "name": "Tiago Rodrigues",
      "email": "tmcrodrigues@gmail.com"
    },
    {
      "name": "Tim",
      "email": "tim-github@baverstock.org.uk"
    },
    {
      "name": "Nick Williams",
      "email": "WickyNilliams@users.noreply.github.com"
    },
    {
      "name": "Louis Larry",
      "email": "louis.larry@gmail.com"
    },
    {
      "name": "Ben Gotow",
      "email": "bengotow@gmail.com"
    },
    {
      "name": "Jakub Gieryluk",
      "email": "jakub.g.opensource@gmail.com"
    },
    {
      "name": "Kevin Lorenz",
      "email": "mail@kevinlorenz.com"
    },
    {
      "name": "Martin von Gagern",
      "email": "Martin.vGagern@gmx.net"
    },
    {
      "name": "Eymen Gunay",
      "email": "eymen@egunay.com"
    },
    {
      "name": "Martin Ek",
      "email": "mail@ekmartin.com"
    },
    {
      "name": "Rafał Pocztarski",
      "email": "r.pocztarski@gmail.com"
    },
    {
      "name": "Mark Reeder",
      "email": "mreeder@uber.com"
    },
    {
      "name": "Chris Rebert",
      "email": "github@chrisrebert.com"
    },
    {
      "name": "Scott Addie",
      "email": "tobias.addie@gmail.com"
    },
    {
      "name": "Jeff McMahan",
      "email": "jeffrey.lee.mcmahan@gmail.com"
    },
    {
      "name": "Tim Krins",
      "email": "timkrins@gmail.com"
    },
    {
      "name": "Hal Henke",
      "email": "halhenke@gmail.com"
    },
    {
      "name": "Julian Simioni",
      "email": "julian@simioni.org"
    },
    {
      "name": "Jimb Esser",
      "email": "jimb@yahoo-inc.com"
    },
    {
      "name": "Alexis Campailla",
      "email": "alexis@janeasystems.com"
    },
    {
      "name": "Chris Chua",
      "email": "chris.sirhc@gmail.com"
    },
    {
      "name": "Beau Gunderson",
      "email": "beau@beaugunderson.com"
    },
    {
      "name": "Dave Galbraith",
      "email": "dave@jut.io"
    },
    {
      "name": "s100",
      "email": "shughes1@uk.ibm.com"
    },
    {
      "name": "Sergey Simonchik",
      "email": "sergey.simonchik@jetbrains.com"
    },
    {
      "name": "Vanja Radovanović",
      "email": "elvanja@gmail.com"
    },
    {
      "name": "Jonathan Persson",
      "email": "persson.jonathan@gmail.com"
    },
    {
      "name": "Vedat Mahir YILMAZ",
      "email": "mahir@vedatmahir.com"
    },
    {
      "name": "Samuel Reed",
      "email": "samuel.trace.reed@gmail.com"
    },
    {
      "name": "Rafał Legiędź",
      "email": "rafal.legiedz@gmail.com"
    },
    {
      "name": "Jan Schär",
      "email": "jscissr@gmail.com"
    },
    {
      "name": "Xcat Liu",
      "email": "xcatliu@gmail.com"
    },
    {
      "name": "harryh",
      "email": "Aourin@users.noreply.github.com"
    },
    {
      "name": "Prayag Verma",
      "email": "prayag.verma@gmail.com"
    },
    {
      "name": "Neil Kistner",
      "email": "neil.kistner@gmail.com"
    },
    {
      "name": "Zoujie Wzj",
      "email": "zoujie.wzj@alibaba-inc.com"
    },
    {
      "name": "Ryan Hendrickson",
      "email": "ryan.hendrickson@alum.mit.edu"
    },
    {
      "name": "Arturo Coronel",
      "email": "aoitsu3@gmail.com"
    },
    {
      "name": "Hutson Betts",
      "email": "hbetts@factset.com"
    },
    {
      "name": "Lewis Cowper",
      "email": "lewis.cowper@googlemail.com"
    },
    {
      "name": "Adam Byrne",
      "email": "misterbyrne@gmail.com"
    },
    {
      "name": "Ifeanyi Oraelosi",
      "email": "ifeanyioraelosi@gmail.com"
    },
    {
      "name": "Robert Ludwig",
      "email": "rob.ludwig@rideamigos.com"
    },
    {
      "name": "Chris Warren",
      "email": "chris@ixalon.net"
    },
    {
      "name": "Scott Plumlee",
      "email": "scott@plumlee.org"
    },
    {
      "name": "Daniel Pedersen",
      "email": "daniel@scandinav.se"
    },
    {
      "name": "rhgb",
      "email": "kaiserdaemon@gmail.com"
    },
    {
      "name": "doug.wade",
      "email": "doug.wade@redfin.com"
    },
    {
      "name": "Zac",
      "email": "zdoege@gm.slc.edu"
    },
    {
      "name": "GriffinSchneider",
      "email": "griffinschneider@gmail.com"
    },
    {
      "name": "Andres Kalle",
      "email": "mjomble@gmail.com"
    },
    {
      "name": "thefourtheye",
      "email": "thefourtheye@users.noreply.github.com"
    },
    {
      "name": "Yael",
      "email": "yaelz@users.noreply.github.com"
    },
    {
      "name": "Yann Odeyer",
      "email": "yann@odeyer.com"
    },
    {
      "name": "James Monger",
      "email": "jameskmonger@hotmail.co.uk"
    },
    {
      "name": "Thomas Hallock",
      "email": "thomas@1stdibs.com"
    },
    {
      "name": "Paul Irish",
      "email": "paul.irish@gmail.com"
    },
    {
      "name": "Paul O'Leary McCann",
      "email": "polm@dampfkraft.com"
    },
    {
      "name": "Francis Gulotta",
      "email": "wizard@roborooter.com"
    },
    {
      "name": "Felix Rieseberg",
      "email": "felix@felixrieseberg.com"
    },
    {
      "name": "Glen Mailer",
      "email": "glenjamin@gmail.com"
    },
    {
      "name": "Federico Brigante",
      "email": "bfred-it@users.noreply.github.com"
    },
    {
      "name": "Steve Mao",
      "email": "maochenyan@gmail.com"
    },
    {
      "name": "Anna Henningsen",
      "email": "anna@addaleax.net"
    },
    {
      "name": "Rachel Evans",
      "email": "git@rve.org.uk"
    },
    {
      "name": "Sam Minnee",
      "email": "sam@silverstripe.com"
    },
    {
      "name": "Zirak",
      "email": "zirakertan@gmail.com"
    },
    {
      "name": "Daniel Lupu",
      "email": "lupu.daniel.f@gmail.com"
    },
    {
      "name": "Gianluca Casati",
      "email": "fibo@users.noreply.github.com"
    },
    {
      "name": "André Herculano",
      "email": "andresilveirah@gmail.com"
    },
    {
      "name": "Wyatt Preul",
      "email": "wpreul@gmail.com"
    },
    {
      "name": "Myles Borins",
      "email": "mborins@us.ibm.com"
    },
    {
      "name": "Elliot Lee",
      "email": "github.public@intelliot.com"
    },
    {
      "name": "Dmitry Kirilyuk",
      "email": "gk.joker@gmail.com"
    },
    {
      "name": "Aaron Tribou",
      "email": "aaron.tribou@gmail.com"
    },
    {
      "name": "Tapani Moilanen",
      "email": "moilanen.tapani@gmail.com"
    },
    {
      "name": "Han Seoul-Oh",
      "email": "laughinghan@gmail.com"
    },
    {
      "name": "Aleksey Shvayka",
      "email": "shvaikalesh@gmail.com"
    },
    {
      "name": "Emma Ramirez",
      "email": "ramirez.emma.g@gmail.com"
    },
    {
      "name": "Julian Duque",
      "email": "julianduquej@gmail.com"
    },
    {
      "name": "Simon MacDonald",
      "email": "simon.macdonald@gmail.com"
    },
    {
      "name": "Adam Stankiewicz",
      "email": "sheerun@sher.pl"
    },
    {
      "name": "Gregers Gram Rygg",
      "email": "gregers.gram.rygg@finn.no"
    },
    {
      "name": "Peter Dave Hello",
      "email": "hsu@peterdavehello.org"
    },
    {
      "name": "Jordan Klassen",
      "email": "forivall@gmail.com"
    },
    {
      "name": "Jason Palmer",
      "email": "jason@jason-palmer.com"
    },
    {
      "name": "Michael Hart",
      "email": "michael.hart.au@gmail.com"
    },
    {
      "name": "Sasha Koss",
      "email": "koss@nocorp.me"
    },
    {
      "name": "David Emmerson",
      "email": "david.emmerson@gmail.com"
    },
    {
      "name": "Christophe Hurpeau",
      "email": "christophe@hurpeau.com"
    },
    {
      "name": "Daniel Paz-Soldan",
      "email": "daniel.pazsoldan@gmail.com"
    },
    {
      "name": "Sakthipriyan Vairamani",
      "email": "thechargingvolcano@gmail.com"
    },
    {
      "name": "Zach Renner",
      "email": "zarenner@microsoft.com"
    },
    {
      "name": "Christopher Hiller",
      "email": "boneskull@boneskull.com"
    },
    {
      "name": "Joshua Bennett",
      "email": "legodudejb@gmail.com"
    },
    {
      "name": "Andrew Meyer",
      "email": "andrewm.bpi@gmail.com"
    },
    {
      "name": "Michael Jasper",
      "email": "mdjasper@gmail.com"
    },
    {
      "name": "Max",
      "email": "contact@mstoiber.com"
    },
    {
      "name": "Szymon Nowak",
      "email": "szimek@gmail.com"
    },
    {
      "name": "Jason Karns",
      "email": "jason.karns@gmail.com"
    },
    {
      "name": "Lucas Holmquist",
      "email": "lholmqui@redhat.com"
    },
    {
      "name": "Ionică Bizău",
      "email": "bizauionica@gmail.com"
    },
    {
      "name": "Alex Chesters",
      "email": "AlexChesters@users.noreply.github.com"
    },
    {
      "name": "Robert Gay",
      "email": "robert.gay@redfin.com"
    },
    {
      "name": "Steven",
      "email": "stevokk@hotmail.com"
    },
    {
      "name": "Tim Caswell",
      "email": "tim@creationix.com"
    },
    {
      "name": "Anna Henningsen",
      "email": "github@addaleax.net"
    },
    {
      "name": "Kim Røen",
      "email": "kim@kimroen.com"
    },
    {
      "name": "Douglas Wilson",
      "email": "dougwilson@live.com"
    },
    {
      "name": "Mike Engel",
      "email": "mike@mike-engel.com"
    },
    {
      "name": "baderbuddy",
      "email": "baderbuddy@gmail.com"
    },
    {
      "name": "Alex Jordan",
      "email": "alex@strugee.net"
    },
    {
      "name": "Ville Lahdenvuo",
      "email": "tuhoojabotti@gmail.com"
    },
    {
      "name": "Natalie Wolfe",
      "email": "nwolfe@newrelic.com"
    },
    {
      "name": "Andrew Schmadel",
      "email": "aschmadel@learningobjects.com"
    },
    {
      "name": "Jonah Moses",
      "email": "jonahkmoses@gmail.com"
    },
    {
      "name": "Daijirō Wachi",
      "email": "daijiro.wachi@gmail.com"
    },
    {
      "name": "Dmitry Litvinchenko",
      "email": "karaliti@gmail.com"
    },
    {
      "name": "chocolateboy",
      "email": "chocolate@cpan.org"
    },
    {
      "name": "Henry Zhu",
      "email": "hi@henryzoo.com"
    },
    {
      "name": "Nate Goldman",
      "email": "ungoldman@gmail.com"
    },
    {
      "name": "Ted Yavuzkurt",
      "email": "hello@TedY.io"
    },
    {
      "name": "Arseniy Maximov",
      "email": "localhost@kern0.ru"
    },
    {
      "name": "Evgeny Kulikov",
      "email": "beyondcompute@users.noreply.github.com"
    },
    {
      "name": "Сковорода Никита Андреевич",
      "email": "chalkerx@gmail.com"
    },
    {
      "name": "Carol",
      "email": "carol.nichols@gmail.com",
      "url": "Nichols || Goulding"
    },
    {
      "name": "Jarid Margolin",
      "email": "jaridmargolin@gmail.com"
    },
    {
      "name": "David Cook",
      "email": "divergentdave@gmail.com"
    },
    {
      "name": "Brian Dukes",
      "email": "bdukes@engagesoftware.com"
    },
    {
      "name": "Jed Fox",
      "email": "git@twopointzero.us"
    },
    {
      "name": "Pavlo Liulia",
      "email": "pavloblack@hotmail.com"
    },
    {
      "name": "Ján Dzurek",
      "email": "famousgarkin@outlook.com"
    },
    {
      "name": "Lucas Theisen",
      "email": "ltheisen@mitre.org"
    },
    {
      "name": "Mike Sherov",
      "email": "mike.sherov@gmail.com"
    },
    {
      "name": "薛定谔的猫",
      "email": "hh_2013@foxmail.com"
    },
    {
      "name": "Paweł Lula",
      "email": "pavloblack@hotmail.com"
    },
    {
      "name": "Jakob Krigovsky",
      "email": "jakob@krigovsky.com"
    },
    {
      "name": "George Rawlinson",
      "email": "george@rawlinson.net.nz"
    },
    {
      "name": "Jack Nagel",
      "email": "jacknagel@users.noreply.github.com"
    },
    {
      "name": "Andreas Kohn",
      "email": "andreas.kohn@gmail.com"
    },
    {
      "name": "Jason Wohlgemuth",
      "email": "jhwohlgemuth@users.noreply.github.com"
    },
    {
      "name": "Ryan Graham",
      "email": "r.m.graham@gmail.com"
    },
    {
      "name": "Hirse",
      "email": "jan.pilzer@gmx.de"
    },
    {
      "name": "Colin Rotherham",
      "email": "work@colinr.com"
    },
    {
      "name": "Aki",
      "email": "hi@akiro.se"
    },
    {
      "name": "Emily Marigold Klassen",
      "email": "forivall@gmail.com"
    },
    {
      "name": "Ramana Venkata",
      "email": "idlike2dream@gmail.com"
    },
    {
      "name": "kierendixon",
      "email": "knitesoulja@hotmail.com"
    },
    {
      "name": "Rácz Tibor Zoltán",
      "email": "racztiborzoltan@gmail.com"
    },
    {
      "name": "Guangcong Luo",
      "email": "guangcongluo@gmail.com"
    },
    {
      "name": "Steven",
      "email": "steven@ceriously.com"
    },
    {
      "name": "Jan Pilzer",
      "email": "jan.pilzer@gmx.de"
    },
    {
      "name": "Leonard Martin",
      "email": "leonard.martin@gmail.com"
    },
    {
      "name": "Teddy Katz",
      "email": "teddy.katz@gmail.com"
    },
    {
      "name": "Simon Legg",
      "email": "leggsimon@gmail.com"
    },
    {
      "name": "Kin Lum",
      "email": "kenneth.kin.lum@gmail.com"
    },
    {
      "name": "David Rousselie",
      "email": "guido.dassori@gmail.com"
    },
    {
      "name": "Jože Mlakar",
      "email": "JozeM@ixtlan-team.si"
    },
    {
      "name": "happylynx",
      "email": "happylynx@users.noreply.github.com"
    },
    {
      "name": "Dominic Watson",
      "email": "intellix@users.noreply.github.com"
    },
    {
      "name": "Enrico Weigelt, metux IT consult",
      "email": "enrico.weigelt@gr13.net"
    },
    {
      "name": "Brian Beck",
      "email": "exogen@gmail.com"
    },
    {
      "name": "Ramana Venkata",
      "email": "vramana@users.noreply.github.com"
    },
    {
      "name": "Misha Kaletsky",
      "email": "misha.kaletsky@gmail.com"
    },
    {
      "name": "Andrew Schmadel",
      "email": "schmod@users.noreply.github.com"
    },
    {
      "name": "AJ Jordan",
      "email": "alex@strugee.net"
    },
    {
      "name": "Mark Banner",
      "email": "standard8@mozilla.com"
    },
    {
      "name": "Richard Simko",
      "email": "richardsimko@users.noreply.github.com"
    },
    {
      "name": "Sanketh Katta",
      "email": "sankethkatta@gmail.com"
    },
    {
      "name": "Tim Needham",
      "email": "tim.needham@wmfs.net"
    },
    {
      "name": "Leonardo Rojas",
      "email": "leonardo.rojas@shopify.com"
    },
    {
      "name": "Mark Peter Fejes",
      "email": "fejes.mark@gmail.com"
    },
    {
      "name": "Ryan Florence",
      "email": "rpflorence@gmail.com"
    },
    {
      "name": "MichaelQQ",
      "email": "mingsian.tu@vpon.com"
    },
    {
      "name": "Anders D. Johnson",
      "email": "anders.d.johnson.developer@gmail.com"
    },
    {
      "name": "Benjamin Fernandes",
      "email": "lotharsee@gmail.com"
    },
    {
      "name": "Simon Kurtz",
      "email": "simonkurtz@gmail.com"
    },
    {
      "name": "David Goss",
      "email": "david@davidgoss.co.uk"
    },
    {
      "name": "Luis Gustavo Pereira",
      "email": "lgp1985@yahoo.com.br"
    },
    {
      "name": "Amos Wenger",
      "email": "fasterthanlime@users.noreply.github.com"
    },
    {
      "name": "Samuel Marks",
      "email": "samuelmarks@gmail.com"
    },
    {
      "name": "Victor Travieso",
      "email": "victor@grabcad.com"
    },
    {
      "name": "Joshua Chaitin-Pollak",
      "email": "jbcpollak@users.noreply.github.com"
    },
    {
      "name": "Brendan Warkentin",
      "email": "faazshift@gmail.com"
    },
    {
      "name": "Scott Santucci",
      "email": "ScottFreeCode@users.noreply.github.com"
    },
    {
      "name": "Xavier Cambar",
      "email": "xcambar@gmail.com"
    },
    {
      "name": "Vikram",
      "email": "nrvikram19@gmail.com"
    },
    {
      "name": "Igor Nadj",
      "email": "igor.nadj@shinesolutions.com"
    },
    {
      "name": "Tong Li",
      "email": "supertong@users.noreply.github.com"
    },
    {
      "name": "tripu",
      "email": "t@tripu.info"
    },
    {
      "name": "Carsten Brandt",
      "email": "mail@cebe.cc"
    },
    {
      "name": "Marcin Szczepanski",
      "email": "marcin@imagichine.com.au"
    },
    {
      "name": "Josh Clow",
      "email": "josh@textio.com"
    },
    {
      "name": "Jakub Holy",
      "email": "jakubholy@jakubholy.net"
    },
    {
      "name": "Alexandra Ulsh",
      "email": "alexandra.ulsh@gmail.com"
    },
    {
      "name": "Tom MacWright",
      "email": "tmcw@users.noreply.github.com"
    },
    {
      "name": "Felicio Mununga",
      "email": "felicio@users.noreply.github.com"
    },
    {
      "name": "Gabor Szabo",
      "email": "gszabo@users.noreply.github.com"
    },
    {
      "name": "Andreas Müller",
      "email": "mail@devmount.de"
    },
    {
      "name": "Andrew Pitman",
      "email": "apitman@users.noreply.github.com"
    },
    {
      "name": "Jacob Wejendorp",
      "email": "jacob@wejendorp.dk"
    },
    {
      "name": "Alejandro López",
      "email": "a.lopez.sanchez@outlook.es"
    },
    {
      "name": "Victor Belozyorov",
      "email": "hi@vbelozyorov.com"
    },
    {
      "name": "Bradley Farias",
      "email": "bfarias@godaddy.com"
    },
    {
      "name": "Kyle E. Mitchell",
      "email": "kyle@kemitchell.com"
    },
    {
      "name": "Tuan Anh Tran",
      "email": "me@tuananh.org"
    },
    {
      "name": "Nathan Woltman",
      "email": "nwoltman@outlook.com"
    },
    {
      "name": "Kyra",
      "email": "kyranet@users.noreply.github.com"
    },
    {
      "name": "Leаh Neukirchen",
      "email": "chneukirchen@gmail.com"
    },
    {
      "name": "Kyle Spier-Swenson",
      "email": "kyleshome@gmail.com"
    },
    {
      "name": "Joe Bowbeer",
      "email": "joe.bowbeer@gmail.com"
    },
    {
      "name": "Nalin Bhardwaj",
      "email": "6984346+nalinbhardwaj@users.noreply.github.com"
    },
    {
      "name": "Nicolas Garnier",
      "email": "ngarnier@users.noreply.github.com"
    },
    {
      "name": "Vladislav Rassokhin",
      "email": "vladrassokhin@gmail.com"
    },
    {
      "name": "Josh Goldberg",
      "email": "joshuakgoldberg@outlook.com"
    },
    {
      "name": "laggingreflex",
      "email": "laggingreflex@gmail.com"
    },
    {
      "name": "Kristofer Selbekk",
      "email": "selbekk@users.noreply.github.com"
    },
    {
      "name": "Sreenivas Alapati",
      "email": "sreenivas9alapati@gmail.com"
    },
    {
      "name": "Ben Creasy",
      "email": "ben@bencreasy.com"
    },
    {
      "name": "Allan Kimmer Jensen",
      "email": "allankimmerjensen@gmail.com"
    },
    {
      "name": "rinfan",
      "email": "rinfan@users.noreply.github.com"
    },
    {
      "name": "Matt Hoyle",
      "email": "code@deployable.co"
    },
    {
      "name": "Mason Pawsey",
      "email": "mpawsey@csub.edu"
    },
    {
      "name": "Johannes Bader",
      "email": "olydis@users.noreply.github.com"
    },
    {
      "name": "Michael Zabka",
      "email": "zabka.michael@gmail.com"
    },
    {
      "name": "Bruce MacNaughton",
      "email": "bmacnaughton@gmail.com"
    },
    {
      "name": "Christopher Patty",
      "email": "christopher.patty@aggiemail.usu.edu"
    },
    {
      "name": "Max Stoiber",
      "email": "contact@mxstbr.com"
    },
    {
      "name": "Enrico Weigelt",
      "email": "enrico.weigelt@gr13.net"
    },
    {
      "name": "David Hu",
      "email": "davidjhu@gmail.com"
    },
    {
      "name": "Steven R. Loomis",
      "email": "srl295@gmail.com"
    },
    {
      "name": "Julien Deniau",
      "email": "julien.deniau@gmail.com"
    },
    {
      "name": "Prasanna Venkatesh T S",
      "email": "vipranarayan14@gmail.com"
    },
    {
      "name": "Alec Larson",
      "email": "aleclarson@users.noreply.github.com"
    },
    {
      "name": "John-David Dalton",
      "email": "john.david.dalton@gmail.com"
    },
    {
      "name": "Raymond Feng",
      "email": "enjoyjava@gmail.com"
    },
    {
      "name": "Tieme van Veen",
      "email": "tiemevanveen@hotmail.com"
    },
    {
      "name": "Finn Pauls",
      "email": "derfinn@gmail.com"
    },
    {
      "name": "Jeremy Kahn",
      "email": "jeremyckahn@gmail.com"
    },
    {
      "name": "Mertcan Mermerkaya",
      "email": "mertcan@mermerkaya.net"
    },
    {
      "name": "Will Yardley",
      "email": "wyardley@users.noreply.github.com"
    },
    {
      "name": "Matt Travi",
      "email": "github@travi.org"
    },
    {
      "name": "Solomon Victorino",
      "email": "solomonvictorino@gmail.com"
    },
    {
      "name": "Rich Trott",
      "email": "rtrott@gmail.com"
    },
    {
      "name": "Maksym Kobieliev",
      "email": "maximaximums@gmail.com"
    },
    {
      "name": "Thomas Reggi",
      "email": "socialtr@gmail.com"
    },
    {
      "name": "David Gilbertson",
      "email": "gilbertson.david@gmail.com"
    },
    {
      "name": "Rob Lourens",
      "email": "roblourens@gmail.com"
    },
    {
      "name": "Karan Thakkar",
      "email": "karanjthakkar@gmail.com"
    },
    {
      "name": "Howard T. Chiam",
      "email": "hchiam@users.noreply.github.com"
    },
    {
      "name": "Geoffrey Mattie",
      "email": "info@geoffreymattie.com"
    },
    {
      "name": "Luis Lobo Borobia",
      "email": "luislobo@gmail.com"
    },
    {
      "name": "Aaron Tribou",
      "email": "tribou@users.noreply.github.com"
    },
    {
      "name": "刘祺",
      "email": "gucong@gmail.com"
    }
  ],
  "dependencies": {
    "JSONStream": "^1.3.3",
    "abbrev": "~1.1.1",
    "ansicolors": "~0.3.2",
    "ansistyles": "~0.1.3",
    "aproba": "~1.2.0",
    "archy": "~1.0.0",
    "bin-links": "^1.1.2",
    "bluebird": "~3.5.1",
    "byte-size": "^4.0.3",
    "cacache": "^11.0.2",
    "call-limit": "~1.1.0",
    "chownr": "~1.0.1",
    "cli-columns": "^3.1.2",
    "cli-table3": "^0.5.0",
    "cmd-shim": "~2.0.2",
    "columnify": "~1.5.4",
    "config-chain": "~1.1.11",
    "debuglog": "*",
    "detect-indent": "~5.0.0",
    "detect-newline": "^2.1.0",
    "dezalgo": "~1.0.3",
    "editor": "~1.0.0",
    "figgy-pudding": "^3.1.0",
    "find-npm-prefix": "^1.0.2",
    "fs-vacuum": "~1.2.10",
    "fs-write-stream-atomic": "~1.0.10",
    "gentle-fs": "^2.0.1",
    "glob": "~7.1.2",
    "graceful-fs": "~4.1.11",
    "has-unicode": "~2.0.1",
    "hosted-git-info": "^2.6.0",
    "iferr": "^1.0.0",
    "imurmurhash": "*",
    "inflight": "~1.0.6",
    "inherits": "~2.0.3",
    "ini": "^1.3.5",
    "init-package-json": "^1.10.3",
    "is-cidr": "^2.0.6",
    "json-parse-better-errors": "^1.0.2",
    "lazy-property": "~1.0.0",
    "libcipm": "^2.0.0",
    "libnpmhook": "^4.0.1",
    "libnpx": "^10.2.0",
    "lock-verify": "^2.0.2",
    "lockfile": "^1.0.4",
    "lodash._baseindexof": "*",
    "lodash._baseuniq": "~4.6.0",
    "lodash._bindcallback": "*",
    "lodash._cacheindexof": "*",
    "lodash._createcache": "*",
    "lodash._getnative": "*",
    "lodash.clonedeep": "~4.5.0",
    "lodash.restparam": "*",
    "lodash.union": "~4.6.0",
    "lodash.uniq": "~4.5.0",
    "lodash.without": "~4.4.0",
    "lru-cache": "^4.1.3",
    "meant": "~1.0.1",
    "mississippi": "^3.0.0",
    "mkdirp": "~0.5.1",
    "move-concurrently": "^1.0.1",
    "node-gyp": "^3.7.0",
    "nopt": "~4.0.1",
    "normalize-package-data": "~2.4.0",
    "npm-audit-report": "^1.3.1",
    "npm-cache-filename": "~1.0.2",
    "npm-install-checks": "~3.0.0",
    "npm-lifecycle": "^2.0.3",
    "npm-package-arg": "^6.1.0",
    "npm-packlist": "~1.1.10",
    "npm-pick-manifest": "^2.1.0",
    "npm-profile": "^3.0.2",
    "npm-registry-client": "^8.5.1",
    "npm-registry-fetch": "^1.1.0",
    "npm-user-validate": "~1.0.0",
    "npmlog": "~4.1.2",
    "once": "~1.4.0",
    "opener": "~1.4.3",
    "osenv": "^0.1.5",
    "pacote": "^8.1.6",
    "path-is-inside": "~1.0.2",
    "promise-inflight": "~1.0.1",
    "qrcode-terminal": "^0.12.0",
    "query-string": "^6.1.0",
    "qw": "~1.0.1",
    "read": "~1.0.7",
    "read-cmd-shim": "~1.0.1",
    "read-installed": "~4.0.3",
    "read-package-json": "^2.0.13",
    "read-package-tree": "^5.2.1",
    "readable-stream": "^2.3.6",
    "readdir-scoped-modules": "*",
    "request": "^2.81.0",
    "retry": "^0.12.0",
    "rimraf": "~2.6.2",
    "safe-buffer": "^5.1.2",
    "semver": "^5.5.0",
    "sha": "~2.0.1",
    "slide": "~1.1.6",
    "sorted-object": "~2.0.1",
    "sorted-union-stream": "~2.1.3",
    "ssri": "^6.0.0",
    "tar": "^4.4.4",
    "text-table": "~0.2.0",
    "tiny-relative-date": "^1.3.0",
    "uid-number": "0.0.6",
    "umask": "~1.1.0",
    "unique-filename": "~1.1.0",
    "unpipe": "~1.0.0",
    "update-notifier": "^2.5.0",
    "uuid": "^3.3.2",
    "validate-npm-package-license": "^3.0.3",
    "validate-npm-package-name": "~3.0.0",
    "which": "^1.3.1",
    "worker-farm": "^1.6.0",
    "wrappy": "~1.0.2",
    "write-file-atomic": "^2.3.0"
  },
  "description": "a package manager for JavaScript",
  "devDependencies": {
    "deep-equal": "~1.0.1",
    "marked": "^0.4.0",
    "marked-man": "~0.2.1",
    "npm-registry-couchapp": "^2.7.1",
    "npm-registry-mock": "~1.1.0",
    "require-inject": "^1.4.3",
    "sprintf-js": "~1.1.1",
    "standard": "^11.0.1",
    "tacks": "~1.2.6",
    "tap": "^12.0.1"
  },
  "directories": {
    "bin": "./bin",
    "doc": "./doc",
    "lib": "./lib",
    "man": "./man"
  },
  "homepage": "https://docs.npmjs.com/",
  "keywords": [
    "install",
    "modules",
    "package manager",
    "package.json"
  ],
  "license": "Artistic-2.0",
  "main": "./lib/npm.js",
  "man": [
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-access.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-adduser.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-audit.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-bin.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-bugs.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-build.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-bundle.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-cache.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-ci.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-completion.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-config.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-dedupe.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-deprecate.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-dist-tag.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-docs.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-doctor.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-edit.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-explore.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-help-search.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-help.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-hook.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-init.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-install-ci-test.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-install-test.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-install.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-link.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-logout.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-ls.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-outdated.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-owner.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-pack.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-ping.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-prefix.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-profile.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-prune.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-publish.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-README.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-rebuild.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-repo.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-restart.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-root.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-run-script.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-search.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-shrinkwrap.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-star.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-stars.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-start.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-stop.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-team.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-test.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-token.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-uninstall.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-unpublish.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-update.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-version.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-view.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm-whoami.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npm.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man1\\npx.1",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man5\\npm-folders.5",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man5\\npm-global.5",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man5\\npm-json.5",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man5\\npm-package-locks.5",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man5\\npm-shrinkwrap.json.5",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man5\\npmrc.5",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man5\\package-lock.json.5",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man5\\package.json.5",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man7\\npm-coding-style.7",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man7\\npm-config.7",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man7\\npm-developers.7",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man7\\npm-disputes.7",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man7\\npm-index.7",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man7\\npm-orgs.7",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man7\\npm-registry.7",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man7\\npm-scope.7",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man7\\npm-scripts.7",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man7\\removing-npm.7",
    "C:\\Projects\\TBF\\Source\\node_modules\\npm\\man\\man7\\semver.7"
  ],
  "name": "npm",
  "preferGlobal": true,
  "repository": {
    "type": "git",
    "url": "git+https://github.com/npm/cli.git"
  },
  "scripts": {
    "dumpconf": "env | grep npm | sort | uniq",
    "prepare": "node bin/npm-cli.js --no-audit --no-timing prune --prefix=. --no-global && rimraf test/*/*/node_modules && make -j4 doc",
    "preversion": "bash scripts/update-authors.sh && git add AUTHORS && git commit -m \"update AUTHORS\" || true",
    "tap": "tap --reporter=classic --timeout 300",
    "tap-cover": "tap --reporter=classic --nyc-arg='--cache' --coverage --timeout 600",
    "test": "standard && npm run test-tap",
    "test-coverage": "npm run tap-cover -- \"test/tap/*.js\" \"test/network/*.js\" \"test/broken-under-*/*.js\"",
    "test-node": "tap --timeout 240 \"test/tap/*.js\" \"test/network/*.js\" \"test/broken-under-nyc*/*.js\"",
    "test-tap": "npm run tap -- \"test/tap/*.js\" \"test/network/*.js\" \"test/broken-under-*/*.js\""
  },
  "version": "6.2.0"
}
