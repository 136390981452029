

var request = require('request')
  , cheerio = require('cheerio')
  , uglify = require('yuglify')
  , commander = require('commander')
  , fs = require('fs')
  , js = ''
  
  , FILE_ENCODING = 'utf8'
  , MINI = './public/javascripts/production-' + (new Date().getTime()) + '.js'
  , COMBINED = './public/javascripts/production-min-' + (new Date().getTime()) + '.js';

  

 
function loadAllScripts(url, queue, fn) {
    if (url.indexOf('http://') < 0 && url.indexOf('https://') < 0) {
        js += url;
        
        if (queue.length > 0) {
            src = queue.shift();
            loadAllScripts(src, queue, fn);
        }
        else {
            fn(js);
            uglify.jsmin(js, function (err, smashed) {
                fs.writeFileSync(MINI, smashed, FILE_ENCODING);
            });
        }
    }
    else {
        getScript(url, queue, fn); 
    }    
}


function getScript(url, queue, fn) {
    request(url, function (error, response, script) {
        if (!error && response.statusCode == 200) {
            js += script;
            
            console.log(' '+ url +' loaded.');
            
            if (queue.length > 0) {
                src = queue.shift();
                loadAllScripts(src, queue, fn);
            }
            else {
                fn(js);
                
                uglify.jsmin(js, function (err, smashed) {
                    fs.writeFileSync(MINI, smashed, FILE_ENCODING);
                });
            }              
        }
        else {
            new Error('Failed to load ' + url);
        }
    });
}


function getPageJS(url, fn) {

    var scripts = [], error = false, i = 0;
    
    request(url, function (err, response, body) {
    
        if (!err && response.statusCode == 200) {
        
            var $ = cheerio.load(body);
            
            $('script').each(function () {
                var src = $(this).attr('src');
                
                if (src) {
                    scripts.push(src);
                    i++;
                    console.log(' found script ' + i);
                } 
                else {
                    scripts.push($(this).text());
                    console.log(' found inline script');
                }
            });
            
            fn(false, scripts);
        }
        else {
            fn(true, scripts);
        }
        
        
    });
};


function concat(website) {
    getPageJS(website, function (error, response) {
        if (!error) {
            if (response.length > 0) {
            
                var scripts = response;
                var script = scripts.shift();
                
                loadAllScripts(script, scripts, function (data) {
                    fs.writeFileSync(COMBINED, data, FILE_ENCODING); 
                });
            }
        }
        else {
            console.log('Failed to  get scripts');
            new Error('Failed to  get scripts');
        }
    })
};

commander
  .version('0.0.1')
  .option('-m, --minify [url]', 'Concats and minifies scripts from given website  [website]', 'website')
  .option('-m, --minify [url]', 'Concats and minifies scripts from given website  [website]', 'website')
  .parse(process.argv);
  




function production() {
    console.log('Minifying scripts for:');
    console.log('  - %s', commander.minify);
    concat(commander.minify);
}

exports.production = production;