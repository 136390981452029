module.exports={
  "_from": "node-gyp@^3.7.0",
  "_id": "node-gyp@3.8.0",
  "_inBundle": false,
  "_integrity": "sha512-3g8lYefrRRzvGeSowdJKAKyks8oUpLEd/DyPV4eMhVlhJ0aNaZqIrNUIPuEWWTAoPqyFkfGrM67MC69baqn6vA==",
  "_location": "/node-gyp",
  "_phantomChildren": {},
  "_requested": {
    "type": "range",
    "registry": true,
    "raw": "node-gyp@^3.7.0",
    "name": "node-gyp",
    "escapedName": "node-gyp",
    "rawSpec": "^3.7.0",
    "saveSpec": null,
    "fetchSpec": "^3.7.0"
  },
  "_requiredBy": [
    "#USER",
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/node-gyp/-/node-gyp-3.8.0.tgz",
  "_shasum": "540304261c330e80d0d5edce253a68cb3964218c",
  "_spec": "node-gyp@^3.7.0",
  "_where": "C:\\Projects\\TBF\\Source",
  "author": {
    "name": "Nathan Rajlich",
    "email": "nathan@tootallnate.net",
    "url": "http://tootallnate.net"
  },
  "bin": {
    "node-gyp": "./bin/node-gyp.js"
  },
  "bugs": {
    "url": "https://github.com/nodejs/node-gyp/issues"
  },
  "bundleDependencies": false,
  "dependencies": {
    "fstream": "^1.0.0",
    "glob": "^7.0.3",
    "graceful-fs": "^4.1.2",
    "mkdirp": "^0.5.0",
    "nopt": "2 || 3",
    "npmlog": "0 || 1 || 2 || 3 || 4",
    "osenv": "0",
    "request": "^2.87.0",
    "rimraf": "2",
    "semver": "~5.3.0",
    "tar": "^2.0.0",
    "which": "1"
  },
  "deprecated": false,
  "description": "Node.js native addon build tool",
  "devDependencies": {
    "bindings": "~1.2.1",
    "nan": "^2.0.0",
    "require-inject": "~1.3.0",
    "tape": "~4.2.0"
  },
  "engines": {
    "node": ">= 0.8.0"
  },
  "homepage": "https://github.com/nodejs/node-gyp#readme",
  "installVersion": 9,
  "keywords": [
    "native",
    "addon",
    "module",
    "c",
    "c++",
    "bindings",
    "gyp"
  ],
  "license": "MIT",
  "main": "./lib/node-gyp.js",
  "name": "node-gyp",
  "preferGlobal": true,
  "repository": {
    "type": "git",
    "url": "git://github.com/nodejs/node-gyp.git"
  },
  "scripts": {
    "test": "tape test/test-*"
  },
  "version": "3.8.0"
}
